import * as React from "react";
import { VStack } from "@chakra-ui/react";
import Layout from "../components/layout";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <VStack width="100%" alignItems="flex-start" wrap="wrap">
        <h2>Über dieses Projekt</h2>
        <p>
          Der Herakles-Verlag ist spezialisiert auf die Veröffentlichung
          fiktiver Bücher. Entstanden ist er nach einer herausragend lustigen
          Folge des Podcasts „Das Podcast-Ufo“, in der Florentin Will und Stefan
          Titze zehn Bücher diskutierten, bei denen sowohl der Titel als auch
          der Name der Autor:innen durch Zufallsgeneratoren erzeugt wurden.
          Diese Bücher waren die ersten, die beim Herakles Verlag „verlegt“
          wurden. Seitdem veröffentlichen wir mindestens einmal pro Woche
          weitere Werke aus dem Bereich „fiktive Fiktion“. Dabei geht es uns
          hauptsächlich darum, Spaß zu haben und regelmäßig zu schreiben.
        </p>
        <h2>Kein Zufall</h2>
        <p>
          Ein Großteil der Titel in unserem Katalog stammt von der Seite{" "}
          <a href="https://www.buchtitelgenerator.de/index.php">
            buchtitelgenerator.de
          </a>
          , fast alle Namen von Autor:innen und auch Protagonist:innen wurden
          mit dem{" "}
          <a href="https://realnamecreator.alexjonas.de/">
            realnamesgenerator.com
          </a>{" "}
          erzeugt. Mit dem Filter <b>„Kein Zufall“</b> könnt ihr euch die Bücher
          des Herakles Verlags anzeigen lassen, deren Titel vom
          Buchtitelgenerator stammen.
        </p>
        <h2>Links</h2>
        <p>
          <a href="https://twitter.com/HeraklesVerlag">Le Twitter</a>
        </p>
        <p>
          <a href="http://podcast-ufo.fail/ufo252-herakles/">
            Podcast-Ufo Folge Herakles
          </a>{" "}
        </p>

        <p>
          <a href="https://www.buchtitelgenerator.de/index.php">
            Buchtitelgenerator
          </a>{" "}
        </p>
        <p>
          <a href="https://realnamecreator.alexjonas.de/">
            {" "}
            realnamesgenerator
          </a>{" "}
        </p>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
